<template>
  <div class="all-box">
    <img class="bg-top" src="@/assets/img/performance/bg-4.png" />
    <img class="bg-bottom" src="@/assets/img/performance/bg-5.png" />
    <div class="foun-top">
      <Breadcrumb class="bread breadcrumb-1400" :list="list"></Breadcrumb>
    </div>
    <div class="foun-box">
      <leftMenu :id="this.listId"></leftMenu>
      <div class="foun">
        <div class="content-list">
          <div class="content-top">
            <div class="top-left">
              <div class="left-bg"></div>
              <div class="left-title">星光大合影</div>
            </div>
            <div class="top-right">
              <el-input
                v-model="input1"
                @clear="handleClear"
                clearable
                class="input"
              >
                <el-button
                  slot="append"
                  @click="search"
                  icon="el-icon-search"
                ></el-button>
              </el-input>
            </div>
          </div>
          <div @click="check" v-if="unfold == true" class="content-icon">
            <i class="el-icon-s-unfold"></i>
            <span>最新</span>
          </div>
          <div @click="check" v-else class="content-icon">
            <i class="el-icon-s-unfold"></i>
            <span>最早</span>
          </div>
          <div
            v-for="item in personList"
            @click="toDetail(item.id)"
            :key="item.id"
            class="content-mid"
          >
            <div class="mid-left">
              <img class="personLeft" :src="item.image" :onerror="defaultImg" />
            </div>
            <div class="mid-right">
              <div class="right-top">{{ item.title }}</div>
              <div class="dynamic">
                <div v-if="item.tag" class="dynamic-box">{{ item.tag }}</div>
                <div v-else class="dynamic-box">--</div>
                <div class="dynamic-time">{{ item.createTime }}</div>
              </div>
              <div class="right-mid">{{ convertToPlainText(item.body) }}</div>
              <div class="right-bom"></div>
            </div>
          </div>
          <div class="bottom">
            <el-pagination
              v-if="this.personList.length > 0"
              background
              layout="prev, pager, next"
              @current-change="handlePageChange"
              :page-sizes="this.pager.pageSizes"
              :current-page="this.pager.pageNo"
              :total="this.number"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>
<script>
import leftMenu from "./LeftMenu";
import Breadcrumb from "../../../components/pc/Breadcrumb.vue";
import { getByLevel, portalHit } from "@/service/ArtsInformation";
export default {
  components: {
    leftMenu,
    Breadcrumb,
  },
  data() {
    return {
      defaultImg:
        'this.src="' + require("../../../assets/imgs/noData.jpg") + '"',
      input1: "",
      listId: 5,
      list: ["人艺资讯", "星光大合影"],
      pager: {
        pageNo: 1,
        pageSize: 10,
        totalPage: 0,
      },
      form: {
        level1: "NEWS",
        level2: "XGDHY",
        orderDirection: "1",
      },
      personList: [],
      number: 0,
      unfold: true,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleClear() {
      this.form.title = "";
      this.getData();
    },
    handlePageChange(val) {
      this.pager.pageNo = val;
      this.getData();
    },
    check() {
      this.unfold = !this.unfold;
      if (this.unfold) {
        this.form.orderDirection = "1";
      } else {
        this.form.orderDirection = "0";
      }
      this.getData();
    },
    search() {
      this.form.title = this.input1;
      this.getData();
    },
    convertToPlainText(html) {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText || "";
    },
    async getData() {
      const params = { ...this.form };
      const res = await getByLevel({
        ...this.pager,
        condition: params,
        isShow: 1,
      });
      this.personList = res.data.data;
      this.pager = {
        pageNo: res.data.pageNo,
        totalPage: res.data.totalPage,
        pageSize: res.data.pageSize,
      };
      this.number = res.data.totalCount;
    },
    toDetail(id) {
      portalHit(id);
      const { href } = this.$router.resolve({
        path: "/ArtsInformation/photoDetail",
        query: {
          id: id
        },
      });
      window.open(href, "_blank");
    },
  },
};
</script>
<style scoped lang="less">
.all-box {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f8f8fa;
  margin-top: -1.0417vw;
  z-index: 2;
  .bg-top {
    position: absolute;
    width: 58.6979vw;
    height: 23.4375vw;
    z-index: -1;
  }
  .bg-bottom {
    position: absolute;
    right: 0;
    bottom: 31.25vw;
    width: 11.1458vw;
    height: 55.2083vw;
    z-index: -1;
  }
  .foun-top {
    width: 15.625vw;
    height: 0.7292vw;
    margin-top: 1.0417vw;
    margin-left: 13.5417vw;
    z-index: 5;
    .bread {
      padding-top: 1.0417vw;
    }
  }

  .foun-box {
    height: auto;
    min-height: 53.75vw; /* 设置最小高度为 1032px */
    margin: 2.6042vw 13.5417vw 2.6042vw 13.5417vw;
    display: flex;
    justify-content: center;

    .foun {
      width: 56.25vw;
      // height: 1860px;
      margin-left: 2.0833vw;
      z-index: 5;
      background: #ffffff;

      .content-list {
        width: 51.0417vw;
        // height: 1760px;
        margin: 2.6042vw;

        .content-top {
          display: flex;
          justify-content: space-between;

          .top-left {
            display: flex;

            .left-bg {
              background-image: url("../../../assets/imgs/introduction.png");
              width: 1.3542vw;
              height: 1.3542vw;
              background-size: contain;
              background-repeat: no-repeat;
            }

            .left-title {
              height: 1.3542vw;
              font-size: 1.3542vw;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #212122;
              line-height: 1.3542vw;
              margin-left: 1.3542vw;
            }
          }

          .top-right {
            width: 14.5833vw;
            height: 1.875vw;
          }
        }

        .content-icon {
          // width: 54px;
          // height: 24px;
          min-width: 2.8125vw;
          max-width: 3.3333vw;
          min-height: 1.25vw;
          margin-top: 2.6042vw;
          background: #f8f8fa;
          font-size: 0.6771vw;
          margin-bottom: 1.3021vw;
          cursor: pointer;
          line-height: 1.25vw;
          text-align: center;

          span {
            margin-left: 0.1042vw;
          }
        }

        .content-mid:first-child {
          margin-top: 1.3021vw;
        }

        .content-mid + .content-mid {
          margin-top: 3.125vw;
        }

        .content-mid {
          width: 51.0417vw;
          height: 9.4792vw;
          display: flex;
          cursor: pointer;

          .mid-left {
            width: 15vw;
            height: 9.4792vw;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .mid-right {
            margin-left: 1.5625vw;
            width: 49.4792vw;
            height: 9.4792vw;

            .right-top {
              margin-top: 0.5208vw;
              height: 1.0417vw;
              width: 100%;
              font-size: 1.0417vw;
              font-weight: 500;
              color: #212122;
              line-height: 1.0417vw;
              text-align: left;
              overflow: hidden;
            }

            .dynamic {
              height: 1.0417vw;
              display: flex;
              margin-top: 0.625vw;

              .dynamic-box {
                // height: 20px;
                // width: 64px;
                min-width: 3.3333vw;
                min-height: 1.0417vw;
                border-radius: 0.1042vw;
                background: #f8d39f;
                font-size: 0.625vw;
                line-height: 1.0417vw;
                text-align: center;
              }

              .dynamic-time {
                height: 1.0417vw;
                // width: 120px;
                min-height: 6.25vw;
                color: #909399;
                margin-left: 1.6667vw;
                font-size: 0.625vw;
                line-height: 1.0417vw;
              }
            }

            .right-mid {
              width: 100%;
              height: 3.125vw;
              font-size: 0.6771vw;
              font-weight: 400;
              color: #909399;
              line-height: 1.0417vw;
              margin-top: 0.8333vw;
              text-align: left;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              /* 显示的行数 */
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .right-bom {
              margin-top: 1.0417vw;
              height: 0.7813vw;
              width: 3.0208vw;
              background-image: url("../../../assets/imgs/bottom-bg.png");
              background-size: contain;
            }
          }
        }
        .bottom {
          margin-top: 2.8646vw;
          width: 100%;
          height: 1.6667vw;
          text-align: center;
        }
      }
    }
  }
}
</style>
<style scoped>
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #f9680d;
}
</style>
